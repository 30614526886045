import { useEffect } from "react";

export const useAnchorClickHandler = (className, hashID) => {
  useEffect(() => {
    if (typeof window === "undefined") return;

    const addScrollClass = (targetID) => {
      const targetElement = document.getElementById(targetID);

      if (targetElement) {
        // Add the class to the target element before scrolling
        targetElement.classList.add(className);
        // Smooth scroll to the element
        targetElement.scrollIntoView({ behavior: "smooth" });
      } else {
        console.warn(`Element with ID '${targetID}' not found.`);
      }
    };

    if (window?.location?.hash) {
      addScrollClass(window?.location?.hash.substring(1));
    }

    const handleAnchorClick = (event) => {
      const anchor = event.target.closest('a[href^="#"]');
      if (!anchor) return;

      event.preventDefault();
      addScrollClass(anchor.getAttribute("href").substring(1)); // Get the ID without the #
    };

    document.addEventListener("click", handleAnchorClick);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("click", handleAnchorClick);
    };
  }, [className]);
};

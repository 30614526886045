import React, { useState, useEffect } from "react";
import "./backToTop.scss";

const BackToTop = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    if (window && document) {
      const controlButtonOnScroll = () => {
        if (window.scrollY == 0) {
          setShowButton(false);
        } else if (window.scrollY > 700) setShowButton(true);
      };
      document.addEventListener("scroll", controlButtonOnScroll);

      return () => {
        document.removeEventListener("scroll", controlButtonOnScroll);
      };
    }
  }, []);

  return showButton ? (
    <button
      type="button"
      aria-label="Back to Top"
      className="back-to-top"
      onClick={() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      }}
    >
      <i className="icon-caret-up" style={{ fontSize: 18, color: "#193256" }} />
    </button>
  ) : (
    ""
  );
};

export default BackToTop;

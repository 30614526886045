import React from "react";

const ToggleButton = React.forwardRef(
  ({ children, onClick, className }, ref) => {
    return (
      <div
        role="button"
        tabIndex={0}
        className={className}
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter" || event.key === "Space") {
            event.preventDefault();
            onClick(event);
          }
        }}
      >
        {children}
      </div>
    );
  }
);

export default ToggleButton;

import React, { useRef, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import ToggleButton from "./ToggleButton";
import { generateMenu } from "../../atoms/GenerateMenuTree";
import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import IconArrowLongRight from "../../atoms/Icons/IconArrowLongRight";
import PreHeader from "./PreHeader";
import "./header.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const MegaMenu = ({ mainHeaderData, preHeaderLinksData }) => {
  const [selectedParentMenu, setSelectedParentMenu] = useState();
  const [selectedChildMenu, setSelectedChildMenu] = useState();
  const [toggle, setToggle] = useState("");
  const megaMenuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (ev) =>
      megaMenuRef.current &&
      !megaMenuRef.current.contains(ev.target) &&
      dismissDropdown();
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const dismissDropdown = () => setToggle("");

  const data = generateMenu(mainHeaderData, "main", "object");

  return (
    <div ref={megaMenuRef} className="mega-menu">
      <div className="d-desk">
        <div className="header-link-container">
          {data?.map((item, id) => {
            if (item?.link?.options?.attributes?.class[0] === "search-icon") {
              return (
                <div className="header-link-search h-100" key={id}>
                  <a aria-label="Go to search page" href={item?.link?.url}>
                    <i className="bi bi-search header-link-search-color"></i>
                  </a>
                </div>
              );
            }
            return (
              <div key={id} className="mm-dropdown-header">
                <Dropdown show={item?.title === toggle} className="dropdown">
                  <Dropdown.Toggle
                    className={`dropdown-toggle-button ${
                      item?.title === toggle ? "dropdown-toggle-bg" : ""
                    }`}
                    as={ToggleButton}
                    onClick={() => {
                      setToggle(item?.title);
                    }}
                  >
                    {item?.title}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Container>
                      <Row>
                        <Col lg={4} xl={4}>
                          <div className="dropdown-teaser">
                            <GatsbyImage
                              image={getImage(
                                item?.relationships?.field_teaser_image
                                  ?.relationships?.field_media_image
                                  ?.gatsbyImage
                              )}
                              alt={item?.field_teaser_title}
                            />
                            <div className="dropdown-teaser__items">
                              <p className="dropdown-teaser__eyebrow">
                                {item?.relationships?.field_product_tags?.name}
                              </p>

                              <h4 className="dropdown-teaser__title font-freight">
                                {item?.field_teaser_title}
                              </h4>

                              <div className="dropdown-teaser__cta">
                                <a
                                  rel="noopener noreferrer"
                                  target="_self"
                                  href={item?.field_teaser_cta?.url}
                                  className="btn white-link btn-inline"
                                >
                                  {item?.field_teaser_cta?.title}
                                </a>
                                <IconArrowLongRight color="white" noHover />
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col lg={8} xl={8}>
                          <Row>
                            <div className="dropdown-cross-icon__wrapper">
                              <h2>{item?.title}</h2>
                              <div
                                role="button"
                                tabIndex={0}
                                className="h-50"
                                onClick={dismissDropdown}
                                onKeyDown={(event) => {
                                  if (
                                    event.key === "Enter" ||
                                    event.key === "Space"
                                  ) {
                                    dismissDropdown();
                                  }
                                }}
                              >
                                <i
                                  aria-label="cross-icon"
                                  className="bi bi-x-lg dropdown-cross-icon"
                                  style={{ fontSize: "2rem" }}
                                ></i>
                              </div>
                            </div>
                            <Row>
                              <div className="b-top-white pb-5 ms-3 w-25"></div>
                            </Row>

                            {item?.children?.map((child, i) => {
                              return (
                                <>
                                  <Col
                                    md={item?.children?.length === 2 ? 6 : 4}
                                    key={i}
                                  >
                                    <p className="typo-heading-teaserBox color-disasterPacificBlue mb-4">
                                      {child.title}
                                    </p>

                                    {child.children.map((grandChild, i) => {
                                      return (
                                        <Dropdown.Item
                                          key={i}
                                          href={grandChild?.link?.url}
                                          className="white-link"
                                        >
                                          {grandChild.title}
                                        </Dropdown.Item>
                                      );
                                    })}
                                  </Col>
                                </>
                              );
                            })}
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            );
          })}
        </div>
      </div>
      <div className="d-mob mega-menu-mobile">
        {data?.map((item, id) => {
          if (item?.link?.options?.attributes?.class[0] === "search-icon") {
            return null;
          }

          return (
            <>
              {selectedParentMenu || selectedChildMenu ? (
                ""
              ) : (
                <div
                  role="button"
                  tabIndex={0}
                  className="d-flex justify-content-between align-items-center pb-4 cursor-pointer"
                  onClick={() => {
                    setSelectedParentMenu(item);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" || event.key === "Space") {
                      setSelectedParentMenu(item);
                    }
                  }}
                  key={id}
                >
                  <p className="color-white typo-heading-teaserBox mb-0">
                    {item?.title}
                  </p>
                  <img
                    loading="lazy"
                    src={"/blue-arrow-underline.svg"}
                    alt="arrow"
                  />
                </div>
              )}
            </>
          );
        })}

        {selectedParentMenu || selectedChildMenu ? (
          ""
        ) : (
          <>
            <div className="kashmir-blue-b-top"></div>
            <PreHeader preHeaderLinksData={preHeaderLinksData} />
          </>
        )}

        {selectedParentMenu && !selectedChildMenu && (
          <>
            <div
              role="button"
              tabIndex={0}
              className="d-flex align-items-center cursor-pointer"
              onClick={() => setSelectedParentMenu()}
              onKeyDown={(event) => {
                if (event.key === "Enter" || event.key === "Space") {
                  setSelectedParentMenu();
                }
              }}
            >
              <img loading="lazy" src={"/blue-back.svg"} alt="icon" />
              <p className="color-white ps-3 mb-0">Main menu</p>
            </div>

            <p className="typo-heading-teaserBox color-disasterPacificBlue py-4 mb-0">
              {selectedParentMenu?.title}
            </p>

            {selectedParentMenu?.children.map((child, i) => {
              return (
                <div
                  className="d-flex align-items-center cursor-pointer"
                  key={i}
                >
                  <div
                    role="button"
                    tabIndex={0}
                    className="dropdown-item white-link mb-2"
                    onClick={() => {
                      setSelectedChildMenu(child);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter" || event.key === "Space") {
                        setSelectedChildMenu(child);
                      }
                    }}
                  >
                    {child?.title}
                    <img
                      loading="lazy"
                      src={"/blue-forward.svg"}
                      className="ps-3"
                      alt="arrow"
                    />
                  </div>
                </div>
              );
            })}
          </>
        )}

        {selectedChildMenu && (
          <>
            <div
              role="button"
              tabIndex={0}
              className="d-flex align-items-center cursor-pointer"
              onClick={() => setSelectedChildMenu()}
              onKeyDown={(event) => {
                if (event.key === "Enter" || event.key === "Space") {
                  setSelectedChildMenu();
                }
              }}
            >
              <img loading="lazy" src={"/blue-back.svg"} alt="icon" />
              <p className="color-white ps-3 mb-0">
                {selectedParentMenu?.title}
              </p>
            </div>

            <p className="typo-heading-teaserBox color-disasterPacificBlue py-4 mb-0">
              {selectedChildMenu?.title}
            </p>

            {selectedChildMenu?.children.map((child, i) => {
              return (
                <a
                  className="dropdown-item white-link mb-2 typo-paragraph"
                  href={child?.link?.url}
                  key={i}
                >
                  {child?.title}
                  <img
                    loading="lazy"
                    src={"/blue-forward.svg"}
                    className="ps-3"
                    alt="arrow"
                  />
                </a>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default MegaMenu;

import * as React from "react";
import parse from "html-react-parser";
import { useFileFile } from "../../hooks/useFileFile";
import "./extractRichText.scss"

export const ExtractRichText = ({ richText, extraClasses }) => {
  const drupalIDs = useFileFile().map((e) => e?.node?.drupal_id);
  const allFiles = useFileFile().map((e) => e?.node);

  let body = "";
  if (richText) {
    body = extractImage(drupalIDs, richText, extraClasses, allFiles);
  }
  return <div className={`rich-text ${extraClasses}`} >{body}</div>;
};

function extractImage(drupalIDs, body, extraClasses, allFiles) {
  return parse(body, {
    transform: (node) => {
      if (node.type === "img" || node.type === "drupal-entity") {
        const currentFile = allFiles.find((file) => node.props["data-entity-uuid"] === file?.drupal_id);

        const imgAlign = node.props["data-align"] || "";
        const height = node.props["height"] || "100%";
        const width = node.props["width"] || "100%";
        const src = node.props["src"] || "";
        const fallbackImage = process.env.GATSBY_DRUPAL_URL + src;
        const imgStyles = {};
        if (width) {
          imgStyles.width = `${width}px!important`;
        }
        if (height) {
          imgStyles.height = `${height}px`;
        }
        return (
          <span className={`imgAlign-${imgAlign}`}>
            <img
              style={imgStyles}
              loading="lazy"
              className={extraClasses}
              alt={node?.props?.alt || "image"}
              src={`${currentFile?.url || fallbackImage}`}
            />
          </span>
        );
      }
      return node;
    },
  });
}

export default ExtractRichText;

import { graphql, useStaticQuery } from "gatsby";
import React, { createContext } from "react";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      localSearchContentTypes {
        store
      }
      allNodeCountryProfile(sort: {title: ASC}) {
        nodes {
          title
          path {
            alias
          }
        }
      }
    }
  `);

  return <AppContext.Provider value={data}>{children}</AppContext.Provider>;
};

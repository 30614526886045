import React, { useState, useEffect } from "react";

const NavbarToggle = React.forwardRef(
  ({ children, onClick, className }, ref) => {
    const [isNavOpen, setIsNavOpen] = useState(false);

    useEffect(() => {
      const bodyTag = document.getElementsByTagName('body')[0];
      if(isNavOpen){ // avoid scroll when popup is open
        bodyTag.classList.add('stop-scrolling')
      } else {
        bodyTag.classList.remove('stop-scrolling')
      }

      if(bodyTag.classList.contains('grid-page-body')){ // make header fixed when popup is opned only for grid page
        const header = document.getElementsByClassName('header-wrapper')
        if(isNavOpen){
          window.scrollTo(0,0)
          header.length && header[0].classList.add('fixed-header')
        } else {
          header.length && header[0].classList.remove('fixed-header')
        }
      }
    }, [isNavOpen])

    return (
      <div className="d-flex align-items-baseline">
        {children}
        <div
          role="button"
          tabIndex={0}
          className={className}
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
            setIsNavOpen(!isNavOpen);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter" || event.key === "Space") {
              event.preventDefault();
              onClick(event);
              setIsNavOpen(!isNavOpen);
            }
          }}
        >
          {isNavOpen ? (
            <i class="bi bi-x-lg"></i>
          ) : (
            <i className="bi bi-justify"></i>
          )}
        </div>
      </div>
    );
  }
);

export default NavbarToggle;
